
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AJLoader from "@/components/AjaxLoader.vue";
import useCallRequest from "@/composables/useCallRequest";

export default defineComponent({
  components: {
    AJLoader,
  },
  setup() {
    const { globalQueue, myQueue, getGlobalQueue, getMyQueue, assignToMe } =
      useCallRequest();

    let loadingGlobalQueue = ref<boolean>(true);
    let loadingMyQueue = ref<boolean>(true);

    // const tableData = ref<Array<IGetOrderForViewDto>>([]);

    // const initialData = ref<Array<IGetOrderForViewDto>>([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Call requests", ["CallRequests"]);

      try {
        await getGlobalQueue();
        if (globalQueue.value !== null) {
          loadingGlobalQueue.value = false;
        }
      } catch (error) {
        console.log("Error: ", error);
      }

      try {
        await getMyQueue();
        console.log(myQueue.value);
        if (myQueue.value !== null) {
          loadingMyQueue.value = false;
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    });

    const assignCallToMe = (id: string) => {
      assignToMe(id);
    };

    const formatDate = (date: Date) => {
      return moment(date).format("DD-MMM-YYYY");
    };

    const refreshGlobaQueue = async () => {
      await getGlobalQueue();
    };

    const refreshMyQueue = async () => {
      await getMyQueue();
    };

    return {
      globalQueue,
      myQueue,
      loadingGlobalQueue,
      loadingMyQueue,
      assignCallToMe,
      formatDate,
      refreshGlobaQueue,
      refreshMyQueue,
    };
  },
});
